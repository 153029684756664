import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
import UniversalLink from "./UniversalLink";
import $ from "jquery";
 class ImgTextLeftServices extends React.Component {

    render() {

            return (
                <div className={'service ' + this.props.classnamee}>
<Container >
<Row>
<Col lg="6">
    </Col>
  <Col lg="6" className="service-box">
    <this.props.headingtag className="service-title">{this.props.heading}</this.props.headingtag>
    <div className="service-text" dangerouslySetInnerHTML={{__html: this.props.text}}></div>
  </Col>
</Row>
</Container>
<img className="service-image" src={this.props.obrazek} alt={this.props.obrazekalt}></img>
    </div>
            );
        }
    };
    export default ImgTextLeftServices;