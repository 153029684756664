import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
import Slider from "react-slick";
import UniversalLink from "./UniversalLink"

export default class Worksmap extends React.Component {
  render() {
    return (
        <Slider
        slidesToShow={3}
        swipeToSlide={true}
        centerMode={true}
        centerPadding={"250px"}
         autoplay={true}
          autoplaySpeed={5000}
        focusOnSelect={true}
        pauseOnHover={false}
        responsive= {[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                centerPadding:"0px",
                centerMode:false
              }
            },
        ]}
        >
        {this.props.works.map((work) => {
          return (
          <div className="works-box">
            <img className="works-img" src={work.works.img.mediaItemUrl}></img>
            <work.works.titleTag className="works-title">{work.title}</work.works.titleTag>
            <div className="works-text" dangerouslySetInnerHTML={{__html: work.content}}></div>
            <UniversalLink to={"/works/" + work.slug} className="works-link">{work.works.link}</UniversalLink>
          </div>
          );
        })}
        </Slider>
    );
  }
}
