import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
import Arrow from "../assets/svg/arrow-down.inline.svg"
import UniversalLink from "./UniversalLink"
import Slider from "react-slick";

export default class HeroService extends React.Component {
    render() {
  return (
    <div className="hero servicepage" style={{ backgroundImage: `url(https://libertywaterproofing.com/wordpress/wp-content/uploads/2021/07/hero-background.jpg)` }}>
      <div className="hero-box">
        <h2 className="hero-title">{this.props.herotitle}</h2>
        <p className="hero-text">{this.props.herotext}</p>
        <div className="hero-buttons">
        <Slider
            slidesToShow={7}
            swipeToSlide={true}
           autoplay={true}
           autoplaySpeed={2000}
            focusOnSelect={true}
            pauseOnHover={false}
            responsive= {[
                {
                  breakpoint: 991,
                  settings: {
                    autoplaySpeed:2000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                  
                  }
                },
            ]}
           
            className={""}
          >
        <div className="iconbox">
        <UniversalLink
                        to={'/flat-roof'}
                        activeClassName={"active"}
                        data-hover={'Flat roof'}
                      > 
            <div className="iconboxslider">
              <span className="icon-flat-roofs"></span>
              <span className="services-heading">FLAT ROOF</span>

            </div>
            </UniversalLink>
            </div>
            <div className="iconbox">
            <UniversalLink
                        to={'/terrace'}
                        activeClassName={"active"}
                        data-hover={'Terace'}
                      > 
            <div className="iconboxslider">
            <span className="icon-teraces"></span>
            <span className="services-heading">TERRACE</span>
           
            </div>
            </UniversalLink>
            </div>
            <div className="iconbox">
            <UniversalLink
                        to={'/green-roof'}
                        activeClassName={"active"}
                        data-hover={'Green roof'}
                      > 
            <div className="iconboxslider">
            <span className="icon-green-roofs"></span>
            <span className="services-heading">GREEN ROOF</span>
           
            </div>
            </UniversalLink>
            </div>
            <div className="iconbox">
            <UniversalLink
                        to={'/bathroom'}
                        activeClassName={"active"}
                        data-hover={'Bathroom'}
                      > 
            <div className="iconboxslider">
            <span className="icon-bathrooms"></span>
            <span className="services-heading">BATHROOM</span>
           
            </div>
            </UniversalLink>
            </div>
            <div className="iconbox">
            <UniversalLink
                        to={'/garage'}
                        activeClassName={"active"}
                        data-hover={'Garage'}
                      > 
            <div className="iconboxslider">
            <span className="icon-garages"></span>
            <span className="services-heading">GARAGE</span>
           
            </div>
            </UniversalLink>
            </div>
            <div className="iconbox">
            <UniversalLink
                        to={'/pool'}
                        activeClassName={"active"}
                        data-hover={'Pool'}
                      > 
            <div className="iconboxslider">
            <span className="icon-pools"></span>
            <span className="services-heading">POOL</span>
            </div>
            </UniversalLink>
            </div>
            <div className="iconbox">
            <UniversalLink
                        to={'/basement'}
                        activeClassName={"active"}
                        data-hover={'Basement'}
                      > 
            <div className="iconboxslider">
            <span className="icon-basements"></span>
            <span className="services-heading">BASEMENT</span>
            </div>
            </UniversalLink>
            </div>
            </Slider>
        </div>
      </div>
    </div>
           );
        }
    }
  
