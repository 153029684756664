import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/HeroService"
import { useStaticQuery, graphql } from "gatsby"
import Works from "../components/Works"
import Question from "../components/Question"
import ImgTextLeftServices from "../components/ImgTextLeftServices"
import ImgTextRightServices from "../components/ImgTextRightServices"

const Bathroom = () => {
    const { wpPage } = useStaticQuery(graphql`
    {
        wpPage(title: {eq: "Green roof"}) {
            greenroofservice {
              firstServiceHeading
              firstServiceImgAlt
              firstServiceTag
              firstServiceText
              secondServiceHeading
              secondServiceImg {
                altText
                sourceUrl
              }
              secondServiceImgAlt
              secondServiceTag
              secondServiceText
              sliderText
              firstServiceImg {
                altText
                sourceUrl
              }
            }
          }
    }
  `)
    return (
  <Layout>
    <div className="service-page">
      <Hero active="bathroom" herotitle="Green roof" herotext={wpPage.greenroofservice.sliderText} ></Hero>
      <ImgTextLeftServices headingtag={wpPage.greenroofservice.firstServiceTag} heading={wpPage.greenroofservice.firstServiceHeading} text={wpPage.greenroofservice.firstServiceText} obrazek={wpPage.greenroofservice.firstServiceImg.sourceUrl} obrazekalt={wpPage.greenroofservice.firstServiceImg.altText}></ImgTextLeftServices>
      <ImgTextRightServices  headingtag={wpPage.greenroofservice.secondServiceTag} heading={wpPage.greenroofservice.secondServiceHeading} text={wpPage.greenroofservice.secondServiceText} obrazek={wpPage.greenroofservice.secondServiceImg.sourceUrl} obrazekalt={wpPage.greenroofservice.secondServiceImg.altText}></ImgTextRightServices>
      <Works></Works>   
      <Question></Question>
    </div>
  </Layout>  
    )
    }

export default Bathroom;
 