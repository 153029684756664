import React from "react"
import { useStaticQuery, StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
import WorksMap from "./WorksMap"
import UniversalLink from "./UniversalLink"

export default class Works extends React.Component {
    render() {
      return (
        <div className="works" id="works">
            <h2 className="section-title cent">Featured works</h2>
            <StaticQuery
              query={graphql`
              query Work {
                allWpWork {
                  nodes {
                    content
                    slug
                    title
                    works {
                      img {
                        altText
                        mediaItemUrl
                      }
                      link
                      titleTag
                    }
                  }
                }
                wpPage(slug: {eq: "home"}) {
                  home {
                    worksButton
                    worksTitle
                  }
                }
              }
              `}
              render={data => (
                <WorksMap works={data.allWpWork.nodes}></WorksMap>
              )}
            />
            <div className="works-button">
              <UniversalLink className="btn-btn">SEE ALL WORKS</UniversalLink>
            </div>
        </div>
      );
    }
  }